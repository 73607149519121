import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const PageletServices = {

  initPersonalInfo: async (host, params, headers) => {
    const request = PageletApi.newRequest.getInstance('personal-hr');
    const result = await request(`${host}/${apis.initPersonalInfo}`, {
      method: 'post',
    });
    return result;
  },
}

export default PageletServices;
