(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@dhr/common"), require("@didi/ec-base"), require("@didi/ec-ehr-base"), require("@didi/iconflow/ec"), require("@didi/pagelets-sdk"), require("react"), require("react-dom"), require("single-spa-react"));
	else if(typeof define === 'function' && define.amd)
		define(["@dhr/common", "@didi/ec-base", "@didi/ec-ehr-base", "@didi/iconflow/ec", "@didi/pagelets-sdk", "react", "react-dom", "single-spa-react"], factory);
	else if(typeof exports === 'object')
		exports["personal-hr"] = factory(require("@dhr/common"), require("@didi/ec-base"), require("@didi/ec-ehr-base"), require("@didi/iconflow/ec"), require("@didi/pagelets-sdk"), require("react"), require("react-dom"), require("single-spa-react"));
	else
		root["personal-hr"] = factory(root["@dhr/common"], root["@didi/ec-base"], root["@didi/ec-ehr-base"], root["@didi/iconflow/ec"], root["@didi/pagelets-sdk"], root["react"], root["react-dom"], root["single-spa-react"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__562__, __WEBPACK_EXTERNAL_MODULE__593__, __WEBPACK_EXTERNAL_MODULE__53__, __WEBPACK_EXTERNAL_MODULE__37__, __WEBPACK_EXTERNAL_MODULE__709__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__514__, __WEBPACK_EXTERNAL_MODULE__25__) => {
return 