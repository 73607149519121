import React, { useState, useEffect } from 'react';
import { getLocale } from '@didi/ec-base';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import PageletApi from '@didi/pagelets-sdk';
import { DHRCardTitle } from '@didi/ec-ehr-base';
import { UpThickOutlined, DownThickOutlined } from '@didi/iconflow/ec';
import { on } from '@dhr/common';
import './style.scoped.less';

const localLocales = manifest.locales || {};

const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, headers },
  } = props;

  const [hrData, setHrdata] = useState([]);
  const [hrAllData, setHrAllData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [initError, setInitError] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth.id,
    functionId: auth.id,
    functionName: window.encodeURI(auth.functionName),
    'Content-Type': 'application/json',
  };
  PageletApi.newRequest.init(newHeaders, 'personal-hr');

  const getHrData = data => {
    // 截取前两条数据
    const remainInfo = data?.slice(2, data?.length);
    let subLength = 0;
    remainInfo.forEach(element => {
      const subDatas = element?.data[0][0].subDatas;
      subLength += subDatas.length;
    });
    setRemainingCount(subLength);
    setHrdata(data?.slice(0, 2));
  };

  const handleRequest = async () => {
    try {
      const data = await PageletServices.initPersonalInfo(apiHost);
      if (data?.code === '100200') {
        setHrAllData(data?.data);
        getHrData(data?.data);
      } else {
        setInitError(true);
      }
    } catch (e) {
      setInitError(true);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  useEffect(() => {
    if (showAll) {
      setHrdata(hrAllData);
      setRemainingCount(0);
    } else {
      getHrData(hrAllData);
    }
  }, [showAll]);

  const prefixCls = 'pagelet-personal-hr';
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const classes = classNames(prefixCls);

  return (
    <div className={classes}>
      <DHRCardTitle showPrefixTag={false} title={'人力系统'} />
      {!initError && (
        <div className="personal-hr-warp">
          {hrData.length > 0 &&
            hrData.map(item => {
              const link = item.data[0][0];
              return (
                <div className="personal-hr-content">
                  <div className="personal-hr-content-left">
                    <span className="text-item-1">
                      <img src={link?.iconUrl} />
                    </span>
                    <span className="text-item-2">{link?.title}:</span>
                  </div>
                  <div className='personal-hr-content-right'>
                    {link?.subDatas?.length > 0 &&
                      link?.subDatas.map(info => {
                      return (
                        <div
                          className="personal-hr-content-item"
                          onClick={() => {
                            window.open(info?.linkUrl, '_blank');
                          }}
                        >
                          <span className="text-item-1">
                            <img src={info?.iconUrl} />
                          </span>
                          <span className="text-item-2">{info?.title}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          <div>
            {hrData.length > 0 && (
              <span
                className="personal-hr-all"
                onClick={() => {
                  setShowAll(!showAll);
                }}
              >
                {showAll ? '收起' : `展开剩余${remainingCount}项`}
                {showAll ? (
                  <UpThickOutlined size={14} className={`${prefixCls}-headerurl-icon`} />
                ) : (
                  <DownThickOutlined size={14} className={`${prefixCls}-headerurl-icon`} />
                )}
              </span>
            )}
          </div>
        </div>
      )}
      {initError && (
        <div className="personal-hr-content-err">
          <img src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png" />
          <span className="text-item-1">服务不可用，请稍候再试。</span>
        </div>
      )}
    </div>
  );
};

export default Pagelet;
